/* You can add global styles to this file, and also import other style files */
.hide-close{
  .btn-close {
    display: none !important;
    opacity: 0 !important;
  }
}

.sk-panel-btn {
    &.stashed {
        background: #e3eaef;
    }

    &:hover {
        background: #edf1f4;
    }
}

/* OVERRIDES */

/* UTILITIES */
.invert-y {
    transform: scaleY(-1);
}

.invert-x {
    transform: scaleX(-1);
}

.ng-invalid:not(form):not(sk-folder-path-control).ng-dirty {
    border-color: #fa6173;
    box-shadow: 0 0 0 1px #fa6173;
  }

  .no-space{
    white-space: pre-wrap;

}

/* Weird character shows up in toastr messages unlesse we do this... it comes from legacy components.css */
#toast-container > *:before {
    content: none !important;
}

.sk-scrollable-typeahead {
    max-height: 400px;
    overflow-y: auto;
}

.margin-x-2 {
    margin: 0 2rem;
}

.input-group button.btn {
    min-width: auto !important;
}

.sk-spin-loader {
    border-radius: 50% !important;
}

button, a {
    &.sk-btn-icon:not(.btn-outline-secondary) {
        &:hover{
        border-color: transparent !important;
        }
        &:focus{
            border-color: transparent !important;
        }
    }
}

.sk-password-toggle:disabled {
    border: none;
}
